import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"
import { useGTMInit} from '@/hooks/useGTM.js'



const Index = () =>{
  React.useEffect(()=>{
    useGTMInit('support','XREAL_AIR_MANUAL_EN&JP&KR&CN_pdf')
  },[])
  return(
    <>
      <DownloadFilePage
        downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_AIR_MANUAL_EN&JP&KR&CN_.pdf`}
      />
    </>
  )
} 

export default Index